import React from "react";
import { BrowserRouter, Route, Link } from "react-router-dom";

function Privacy() {
  return (
    <div>
    <h1><center>Privacy Policy</center></h1>
    <br></br>
    <h2><center>1: Your data is never stored on this website.</center></h2>
    <br></br>
    <h2><center>2: When you export events to Google Calender, we will only use your user data to add the events that you have in the table to your Google Calender. Your Google user data will not be used in any other way.</center></h2>
    <br></br>
    <h2><center>3: This app has a valid SSL certificate, so any data you send to our app is secure.</center></h2>
    <footer>
          <i>
            <p>Copyright © 2022 1001tech.net. All Rights Reserved. <a href="https://www.flaticon.com/free-icons/book" title="book icons">Book icons created by Good Ware - Flaticon</a></p>
          </i>
        </footer>
    </div>
  );
}

export default Privacy;