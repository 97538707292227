// WARNING This code is protected under Copyright Law of the United States Title 17 Chapter 1.
// Application created using mui library.

import React, { Component } from "react";
import { BrowserRouter, Route, Link } from "react-router-dom";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import CloseIcon from "@mui/icons-material/Close";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import TablePagination from "@mui/material/TablePagination";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Switch from "@mui/material/Switch";
import DeleteIcon from "@mui/icons-material/Delete";
import FilterListIcon from "@mui/icons-material/FilterList";
import { visuallyHidden } from "@mui/utils";
import TuneIcon from "@mui/icons-material/Tune";
import ClearAllIcon from "@mui/icons-material/ClearAll";
import ClearAll from "@mui/icons-material/ClearAll";
import CalculateIcon from "@mui/icons-material/Calculate";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FormLabel from "@mui/material/FormLabel";
import { FormGroup } from "@mui/material";
import Fab from '@mui/material/Fab';
import FeedbackIcon from '@mui/icons-material/Feedback';

function createData(selectID, date, daysFrom, description) {
  return { selectID, date, daysFrom, description };
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "date",
    numeric: false,
    disablePadding: false,
    label: "Date",
  },
  {
    id: "daysFrom",
    numeric: true,
    disablePadding: false,
    label: "Days From Check-in Date",
  },
  {
    id: "description",
    numeric: false,
    disablePadding: false,
    label: "Description",
  },
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    currentRows,
    setRows,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {/*<TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "Select all Events",
            }}
          />
          </TableCell>*/}
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={"left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
  rowsSelected: PropTypes.array,
  currentRows: PropTypes.array.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const { numSelected, rowsSelected, currentRows, setRows } = props;

  const handleDelete = (e) => {
    console.log("Delete" + e);
    console.log(currentRows);
    setRows(currentRows.splice(e));
    console.log(currentRows);
  };

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography></Typography>
      )}
      {console.log(rowsSelected)}
      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            {/*Function was activating on its own without this.*/}
            <DeleteIcon
              onClick={function () {
                handleDelete(rowsSelected);
              }}
            />
          </IconButton>
        </Tooltip>
      ) : (
        <Typography />
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  currentRows: PropTypes.array.isRequired,
  setRows: PropTypes.func.isRequired,
};

function DaysOutPage() {
  const numberDays = "30";
  const [state, setState] = React.useState({
    numberofDays: numberDays,
  });
  const [finalValue, setFinalValue] = React.useState("");
  const [customDays, setCustomDays] = React.useState("0");
  const [dateValue, setDateValue] = React.useState(new Date());
  const [dateDescription, setDescription] = React.useState("");
  const [tripName, setName] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [newDateValue, setNewValue] = React.useState(new Date());
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [rows, setRows] = React.useState([]);
  const [lastID, addID] = React.useState(0);
  const [revealCustom, setRevealCustom] = React.useState(false);
  const [revealAdvanced, setRevealAdvanced] = React.useState(false);
  const [tableTheme, setTableTheme] = React.useState("striped");
  const [includeDate, setInlcudeDate] = React.useState(true);
  const [includeName, setIncludeName] = React.useState(true);
  const [multipleDates, setMultipleDates] = React.useState(false);
  const [snackOpen, setSnackOpen] = React.useState(false);
  const [diaOpen, setDiaOpen] = React.useState(false);

  const fabStyle = {
    position: 'absolute',
    bottom: 50,
    left: 16,
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const addCalEvent = (e) => {
    var gapi = window.gapi;
    var CLIENT_ID = process.env.REACT_APP_GOOGLE_CALENDER_CLIENT;
    var API_KEY = process.env.REACT_APP_GOOGLE_CALENDER_KEY;
    var DISCOVERY_DOCS = [
      "https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest",
    ];
    var SCOPES = "https://www.googleapis.com/auth/calendar.events";
    gapi.load("client:auth2", () => {
      console.log("Client loaded.");

      gapi.client.init({
        apiKey: API_KEY,
        clientId: CLIENT_ID,
        discoveryDocs: DISCOVERY_DOCS,
        scope: SCOPES,
        plugin_name: "YOUR_STRING_HERE",
      });

      gapi.client.load("calendar", "v3", () =>
        console.log("Login successful.")
      );

      gapi.auth2
        .getAuthInstance()
        .signIn()
        .then(() => {
          for (let x in rows) {
            let d = new Date(rows[x].date);
            let f = new Date(d);
            f.setDate(d.getDate() + 1);
            var event = {
              summary: rows[x].description,
              location: "Online",
              description: "",
              start: {
                dateTime: d,
                timeZone: "America/New_York",
              },
              end: {
                dateTime: f,
                timeZone: "America/New_York",
              },
              recurrence: ["RRULE:FREQ=DAILY;COUNT=1"],
              reminders: {
                useDefault: false,
                overrides: [{ method: "email", minutes: 24 * 60 }],
              },
            };

            var request = gapi.client.calendar.events.insert({
              calendarId: "primary",
              resource: event,
            });

            request.execute((event) => {
              console.log(event);
            });
            console.log("Event created!");
          }
          console.log(rows);
          if (rows == "") {
          } else {
            setSnackOpen(true);
          }
        });
    });
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.selectID);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const isSelected = (selectID) => selected.indexOf(selectID) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.

  const handleCustomDayChange = (e) => {
    setCustomDays(e.target.value);
  };

  const handleDateDescriptionChange = (e) => {
    setDescription(e.target.value);
  };

  const handleDateChange = (newValue) => {
    const d = +dateValue;
    if (d === +newValue) {
      setDateValue(newValue);
    } else {
      if (rows.length === 0 || multipleDates) {
        setDateValue(newValue);
      } else {
        setOpen(true);
        setNewValue(newValue);
      }
    }
  };

  const cancelClear = (e) => {
    /// Something wrong here, when you clear it the text is right but the date picker still has the date you selected.
    setOpen(false);
  };

  const clearTable = (e) => {
    setRows([]);
    setDateValue(newDateValue);
    setOpen(false);
  };

  const handleNumberofDaysChange = (e) => {
    console.log(e.target.value);
    setState({ numberofDays: e.target.value });
    if (e.target.value === "custom") {
      setRevealCustom(true);
    } else {
      setRevealCustom(false);
    }
  };

  const handleTripNameChange = (e) => {
    setName({ tripName: e.target.value });
    console.log(tripName);
  };

  const changeTableTheme = (e) => {
    setTableTheme(e.target.value);
  };

  const handleExportButtonPressed = (e) => {
    const doc = new jsPDF();
    let f = new Date(dateValue);
    f = String(f).substring(0, 15);
    let name = JSON.stringify(tripName);
    name = name.replace('{"tripName":"', "");
    name = name.replace('"}', "");
    if (name === '""' || name === "") {
      name = "Unnamed Trip";
    } else {
    }
    if (
      includeDate === true &&
      includeName === false &&
      multipleDates === false
    ) {
      doc.text("Check-in Date: " + f, 14, 10);
    } else if (
      (includeName === true && includeDate === false) ||
      (includeName === true && multipleDates === true)
    ) {
      doc.text(name, 14, 10);
    } else if (
      includeName === true &&
      includeDate === true &&
      multipleDates === false
    ) {
      doc.text(name + " - " + "Check-in Date: " + f, 14, 10);
    } else {
    }

    autoTable(doc, {
      html: "#datesTable",
      theme: tableTheme,

      styles: { overflow: "ellipsize", minCellWidth: 40 },
    });

    doc.save(name);
  };

  const handleSubmitButtonPressed = (e) => {
    let d = new Date(dateValue);
    console.log(state.numberofDays);
    switch (state.numberofDays) {
      case "30":
        d.setDate(d.getDate() - 30);
        d = String(d).substring(0, 15);
        setFinalValue(d);
        break;
      case "60":
        d.setDate(d.getDate() - 60);
        d = String(d).substring(0, 15);
        setFinalValue(d);
        break;
      case "90":
        d.setDate(d.getDate() - 90);
        d = String(d).substring(0, 15);
        setFinalValue(d);
        break;
      case "custom":
        d.setDate(d.getDate() - Number(customDays));
        d = String(d).substring(0, 15);
        setFinalValue(d);
        break;
    }
    addID(lastID + 1);
    if (multipleDates) {
      if (state.numberofDays === "custom") {
        setRows((rows) => [
          ...rows,
          createData(
            lastID,
            "Check-in: " + String(d).substring(0, 15) + " | Date: " + d,
            Number(customDays),
            dateDescription
          ),
        ]);
      } else {
        setRows((rows) => [
          ...rows,
          createData(
            lastID,
            "Check-in: " + String(d).substring(0, 15) + " | Date: " + d,
            Number(state.numberofDays),
            dateDescription
          ),
        ]);
      }
    } else {
      if (state.numberofDays === "custom") {
        setRows((rows) => [
          ...rows,
          createData(lastID, d, Number(customDays), dateDescription),
        ]);
      } else {
        setRows((rows) => [
          ...rows,
          createData(lastID, d, Number(state.numberofDays), dateDescription),
        ]);
      }
    }
    setDescription("");
    setCustomDays(0);
    console.log(rows);
  };

  const openAdvanced = (e) => {
    setRevealAdvanced((revealAdvanced) => !revealAdvanced);
  };

  const handleNameCheck = (e) => {
    setIncludeName(e.target.checked);
  };

  const handleDateCheck = (e) => {
    setInlcudeDate(e.target.checked);
  };

  const handleMDCheck = (e) => {
    setMultipleDates(e.target.checked);
  };

  const diaExit = (e) => {
    setDiaOpen(false);
  };
  const diaGo = (e) => {
    setDiaOpen(false);
    addCalEvent();
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackOpen(false);
  };

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const calButton = (e) => {
    setDiaOpen(true);
  };

  return (
    <Grid
      container
      spacing={1.5}
      align="center"
      justify="center"
      alignItems="center"
    >
      <Grid item >
      <Tooltip title="Need help? Have a suggestion? Click this button to send me an email.">
      <Fab color="primary" aria-label="add" sx={fabStyle} href="mailto:dev@1001tech.net">
        <FeedbackIcon />
      </Fab>
      </Tooltip>
      </Grid>
      <Grid item xs={12} align="center">
        <Dialog
          open={open}
          onClose={cancelClear}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Clear current table?"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Since you already have items on your table, changing the first day
              date would result in the exported PDF being incorrect. Clearing
              the table will fix this problem.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={cancelClear}>Cancel</Button>
            <Button onClick={clearTable} autoFocus>
              Clear Table
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
      <Grid item xs={12} align="center">
        <Dialog
          open={diaOpen}
          onClose={diaExit}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              WARNING: This feature is experimental. We are working now to get
              our app verified with Google, but for the present time, it may not
              work. If you continue, Google will warn you that our app is
              unverified. If you wish to continue, click continue, sign in with your Google account, click "Advanced", then click
              "Go to 1001tech.net (unsafe)". Our app will never store or sell
              your data, the only purpose of this feature is to add your events
              to your Google Calender.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={diaExit}>Go Back</Button>
            <Button onClick={diaGo} autoFocus>
              Continue
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
      <Grid item xs={12} align="center">
        <Typography component="h4" variant="h4" fontFamily={""}>
          Days Out Calculator
        </Typography>
      </Grid>
      <Grid item xs={12} align="center">
        <FormControl>
          <FormHelperText>
            <div align="center">Trip Name</div>
          </FormHelperText>
          <TextField
            id="tripName"
            required={true}
            onChange={handleTripNameChange}
            defaultValue={""}
            inputProps={{
              style: { textAlign: "center" },
            }}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} align="center">
        <FormHelperText>
          <div align="center">Check-in Date</div>
        </FormHelperText>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DesktopDatePicker
            inputFormat="MM/dd/yyyy"
            value={dateValue}
            onChange={handleDateChange}
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>
      </Grid>
      <Grid item xs={12} align="center">
        <FormControl>
          <FormHelperText>
            <div align="center">Description of Date</div>
          </FormHelperText>
          <TextField
            id="dateDescription"
            required={true}
            value={dateDescription}
            multiline
            onChange={handleDateDescriptionChange}
            inputProps={{
              style: { textAlign: "center" },
            }}
          />
        </FormControl>
      </Grid>

      <Grid item xs={12} align="center">
        <FormControl component="fieldset">
          <FormHelperText>
            <div align="center">Number of Days from Check-in Date</div>
          </FormHelperText>
          <RadioGroup
            row
            defaultValue={numberDays}
            onChange={handleNumberofDaysChange}
          >
            <FormControlLabel
              value="30"
              control={<Radio color="primary" />}
              label="30"
              labelPlacement="bottom"
            />
            <FormControlLabel
              value="60"
              control={<Radio color="primary" />}
              label="60"
              labelPlacement="bottom"
            />
            <FormControlLabel
              value="90"
              control={<Radio color="primary" />}
              label="90"
              labelPlacement="bottom"
            />
            <FormControlLabel
              value="custom"
              control={<Radio color="secondary" />}
              label="Custom"
              labelPlacement="bottom"
            />
          </RadioGroup>
        </FormControl>
      </Grid>
      {revealCustom && (
        <Grid item xs={12} align="center">
          <FormControl>
            <FormHelperText>
              <div align="center">Custom Number of Days</div>
            </FormHelperText>
            <TextField
              id="customDays"
              required={true}
              type="number"
              value={customDays}
              onChange={handleCustomDayChange}
              inputProps={{
                min: 0,
                style: { textAlign: "center" },
              }}
            />
          </FormControl>
        </Grid>
      )}

      <Grid item xs={12} align="center">
        <Button
          color="secondary"
          variant="outlined"
          to="/"
          onClick={openAdvanced}
          endIcon={<TuneIcon />}
        >
          Advanced Options
        </Button>
      </Grid>
      {revealAdvanced && (
        <Grid container item xs={12} justifyContent="center">
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>Customize PDF</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <FormControl>
                <FormHelperText id="demo-radio-buttons-group-label">
                  Table Theme
                </FormHelperText>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  value={tableTheme}
                  name="radio-buttons-group"
                  onChange={changeTableTheme}
                >
                  <FormControlLabel
                    value="striped"
                    control={<Radio />}
                    label="Striped"
                  />
                  <FormControlLabel
                    value="grid"
                    control={<Radio />}
                    label="Grid"
                  />
                  <FormControlLabel
                    value="plain"
                    control={<Radio />}
                    label="Plain"
                  />
                </RadioGroup>
              </FormControl>

              <pre>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={includeName}
                        onChange={handleNameCheck}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    }
                    label="Include Trip Name"
                  />
                  <FormControlLabel
                    control={
                      <Switch
                        checked={multipleDates ? false : includeDate}
                        onChange={handleDateCheck}
                        inputProps={{ "aria-label": "controlled" }}
                        disabled={multipleDates}
                      />
                    }
                    label="Include Check-in Date                 "
                  />
                </FormGroup>
              </pre>
            </AccordionDetails>
          </Accordion>
        </Grid>
      )}
      {revealAdvanced && (
        <Grid container item xs={12} justifyContent="center">
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>Customize Table</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      checked={multipleDates}
                      onChange={handleMDCheck}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  }
                  label="Allow for Multiple Check-in Dates"
                />
              </FormGroup>
            </AccordionDetails>
          </Accordion>
        </Grid>
      )}
      <Grid item xs={12} align="center">
        <Button
          color="primary"
          onClick={handleSubmitButtonPressed}
          variant="contained"
          endIcon={<CalculateIcon />}
        >
          Calculate Dates
        </Button>
      </Grid>
      <Grid item xs={12} align="center">
        <Button
          color="error"
          variant="outlined"
          to="/"
          onClick={clearTable}
          endIcon={<ClearAll />}
        >
          Clear Table
        </Button>
      </Grid>

      <Grid item xs={12} align="center">
        <Paper sx={{ width: "100%", mb: 2 }}>
          {/*<EnhancedTableToolbar
              numSelected={selected.length}
              rowsSelected={selected}
              currentRows={rows}
              setRows={setRows}
              
            />*/}
          <TableContainer>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size={dense ? "small" : "medium"}
              id="datesTable"
            >
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
                setRows={setRows}
                currentRows={rows}
              />
              <TableBody>
                {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
                {stableSort(rows, getComparator(order, orderBy)).map(
                  (row, index) => {
                    const isItemSelected = isSelected(row.selectID);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow
                        hover
                        onClick={(event) => handleClick(event, row.selectID)}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.selectID}
                        selected={isItemSelected}
                      >
                        {/*<TableCell padding="checkbox">
                            <Checkbox
                              color="primary"
                              checked={isItemSelected}
                              inputProps={{
                                "aria-labelledby": labelId,
                              }}
                            />
                            </TableCell>*/}
                        <TableCell align="left">{row.date}</TableCell>
                        <TableCell align="left">{row.daysFrom}</TableCell>
                        <TableCell align="left">{row.description}</TableCell>
                      </TableRow>
                    );
                  }
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Grid>
      <Grid item xs={12} align="center">
        <Button
          color="secondary"
          variant="contained"
          to="/"
          onClick={handleExportButtonPressed}
          endIcon={<FileDownloadIcon />}
        >
          Export Table to PDF
        </Button>
      </Grid>

      <Grid item xs={12} align="center">
        <Button
          color="primary"
          variant="contained"
          to="/"
          onClick={calButton}
          endIcon={<CalendarMonthIcon />}
        >
          Export Events to Google Calender
        </Button>
      </Grid>
      <Grid item xs={12} align="right">
        <Snackbar
          open={snackOpen}
          autoHideDuration={6000}
          onClose={handleClose}
        >
          <Alert
            onClose={handleClose}
            severity="success"
            sx={{ width: "100%" }}
          >
            Events successfully added.
          </Alert>
        </Snackbar>
      </Grid>
      <Grid item xs={12} align="right">
        <footer>
          <i>
            <p>Copyright © 2022 1001tech.net. All Rights Reserved.</p>
          </i>
        </footer>
      </Grid>
    </Grid>
  );
}

export default DaysOutPage;
