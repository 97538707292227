import ReactDOM from "react-dom";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import DaysOutPage from "./DaysOut";
import Privacy from "./Privacy";

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/">
          <Route index element={<DaysOutPage />} />
          <Route path="privacy" element={<Privacy />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

ReactDOM.render(<App />, document.getElementById("root"));
